import ReportsList from 'Pages/Report/list'
import React, { useEffect, useState } from 'react'
import {
  FormService,
  PageService,
  PageServiceService,
  PdfConfigService,
  QuestionnaireService,
} from 'services'
import WizardListing from 'Pages/Wizard/report'
import InvestmentElement from 'Pages/InvestmentElement'
import CustomPieChart from 'Components/Charts/pie'
import ProgressBar from 'Components/Charts/progressBar'
import { useSelector } from 'react-redux'
import { get } from 'lodash'
import { useNavigate } from 'react-router-dom'
import { Button, Modal } from 'antd'
import { PlusOutlined, ArrowLeftOutlined, ArrowRightOutlined } from '@ant-design/icons'

import './element.scss'
import WorkBook from 'Pages/Workbook/workbook'
import WorkbookSetting from './workbookSetting'
import FormPreview from 'Pages/Admin/Forms/AddForm/FormPreview'
import CustomDonutChart from 'Components/Charts/donut'
import CustomBarChart from '../Charts/bar'
import CustomLine from '../Charts/line'
import BarStackedChart from '../Charts/barStacked'
import {
  AdminAboutUs,
  AdminContactUS,
  AdminPrivacyPolicy,
  AdminTermsAndConditions,
  Capability,
  Fields,
  Groups,
  PricingPlan,
} from 'Pages'
import FormList from 'Pages/Admin/Forms/list'
import PageList from 'Pages/Admin/Pages/list'
import QuestionnaireListing from 'Pages/Questionnaire/questionnaire'
import PortfolioListing from 'Pages/Portfolio/list'
import PortfolioRecommendation from 'Pages/Portfolio/recommendation'
import AdminPageConfig from 'Pages/Admin/PageConfig/Admin/list'
import BusinessPageConfig from 'Pages/Admin/PageConfig/Business/list'
import UserPageConfig from 'Pages/Admin/PageConfig/User/list'
import ClientPageConfig from 'Pages/Admin/PageConfig/Client/list'
import PDFTemplateList from 'Pages/Admin/PDF/Template/list'
import PDFConfigList from 'Pages/Admin/PDF/Config/list'
import PDFPageTypes from 'Pages/Admin/PDF/Type/list'
import AdminList from 'Pages/Admin/EntityManagement/Admin/list'
import UserList from 'Pages/Admin/EntityManagement/User/list'
import ClientList from 'Pages/Admin/EntityManagement/Client/list'
import BusinessList from 'Pages/Admin/EntityManagement/Business/list'
import AccessControl from '../../Pages/Auth/AcessControl'
import TableConfig from '../../Pages/Admin/PDF/Template/table'
import LoginHistory from '../../Pages/Auth/LoginHistory'
import StrategySetting from '../../Pages/StrategySetting/StrategySetting'
import ProductConfig from '../../Pages/ProductConfig/ProductConfig'
import ObjectiveConfig from '../../Pages/ObjectiveConfig/ObjectiveConfig'
import StrategySelection from '../../Pages/Strategy/strategy'
import AdviceStrategyHistory from '../../Pages/Strategy/adviceStrategyHistory'
import AdviceDocConfigSetting from '../../Pages/AdviceDocConfig/AdviceDocConfigSetting'
import KnowledgeArea from '../../Pages/Strategy/knowledgeArea'
import AssetTransaction from "../../Pages/AssetElement/AssetTransaction";
import AssetOption from "../../Pages/AssetElement/AssetOption";

const ElementField = ({
  element,
  addWorkbookSettingStatus,
  addWorkbookSettingProgress,
  progress = [],
  handleDragChange,
  statuses,
  deleteRecord,
  getFormDetails,
  handleWorkbookDrag,
  formRecords = [],
  handleWorkbookCardClick,
  getFormRecords,
  preview,
  wizard_history,
  wizard_is_locked,
}) => {
  const [reports, setReports] = useState([])
  const [wizards, setWizards] = useState([])
  const [formModal, setFormModal] = useState(false)
  const [groupModal, setGroupModal] = useState(false)
  const [form, setForm] = useState({})
  const [group, setGroup] = useState({})
  const previewUser = useSelector((state) => state.previewUser)
  const user = useSelector((state) => state.user)
  const queryString = window.location.search
  const params = new URLSearchParams(queryString)
  const entity = params.get('entity') || user?.id
  const entity_type = params.get('entity_type') || user?.user_type
  let navigate = useNavigate()
  useEffect(() => {
    if (element?.element?.type == 'report') {
      getReports()
    }
    if (element?.element?.type == 'wizard') {
      getWizards()
    }
  }, [element?.element?.id])
  const getForm = () => {
    FormService.getForm(element?.element?.form).then((res) => {
      setForm(res?.data?.data)
    })
  }

  const getGroup = () => {
    PageServiceService.GroupDetail(element?.element?.group).then((res) => {
      setGroup(res?.data?.data)
    })
  }
  const getReports = async () => {
    const res = await PdfConfigService.getPageTemplates({
      element: element.element.id,
      entity: previewUser?.details?.id || user?.details?.id,
    })
    const res_data = get(res, 'data.data', [])
    setReports(res_data)
    if (res_data.length === 1) {
      navigate(`report-history?report=${res_data[0].id}`, { push: true })
    }
  }
  const getWizards = async () => {
    const res = await QuestionnaireService.getQuestionnaires({
      element: element.element.id,
    })
    const res_data = get(res, 'data.data', [])
    setWizards(res_data)
    if (res_data.length === 1) {
      navigate(
        `wizard-history?wizard=${res_data[0].id}&entity=${entity}&entity_type=${entity_type}`,
        { push: true }
      )
    }
  }

  const [size, setSize] = useState(100)

  const increaseSize = () => {
    switch (size) {
      case 33.333:
        setSize(50)
        break
      case 50:
        setSize(100)
        break
      case 100:
        setSize(33.333)
        break
    }
  }

  const decreaseSize = () => {
    switch (size) {
      case 33.333:
        setSize(33.333)
        break
      case 50:
        setSize(33.333)
        break
      case 100:
        setSize(50)
        break
    }
  }

  const [isModalVisible, setIsModalVisible] = useState(false)

  const showModal = () => {
    setIsModalVisible(true)
  }

  const handleOk = () => {
    setIsModalVisible(false)
  }

  const handleCancel = () => {
    setIsModalVisible(false)
  }

  return (
    <>
      {element.element.type == 'element_fields' && <Fields noAdminLayout />}
      {element.element.type == 'element_groups' && <Groups noAdminLayout />}
      {element.element.type == 'element_forms' && <FormList noAdminLayout />}
      {element.element.type == 'element_pages' && <PageList noAdminLayout />}
      {element.element.type == 'element_questionnaire' && <QuestionnaireListing noAdminLayout />}
      {element.element.type == 'element_portfolio' && <PortfolioListing noAdminLayout />}
      {element.element.type == 'element_portfolio_recommendation' && (
        <PortfolioRecommendation noAdminLayout />
      )}
      {element.element.type == 'element_page_config_admin' && <AdminPageConfig noAdminLayout />}
      {element.element.type == 'element_page_config_business' && (
        <BusinessPageConfig noAdminLayout />
      )}
      {element.element.type == 'element_page_config_user' && <UserPageConfig noAdminLayout />}
      {element.element.type == 'element_page_config_client' && <ClientPageConfig noAdminLayout />}
      {element.element.type == 'element_pdf_config_template' && <PDFTemplateList noAdminLayout />}
      {element.element.type == 'element_pdf_config_config_pages' && <PDFConfigList noAdminLayout />}
      {element.element.type == 'element_pdf_config_default_pages' && <PDFPageTypes noAdminLayout />}
      {element.element.type == 'element_entities_admin' && <AdminList noAdminLayout />}
      {element.element.type == 'element_entities_user' && <UserList noAdminLayout />}
      {element.element.type == 'element_entities_client' && <ClientList noAdminLayout />}
      {element.element.type == 'element_entities_business' && <BusinessList noAdminLayout />}
      {element.element.type == 'element_settings_contact_us' && <AdminContactUS noAdminLayout />}
      {element.element.type == 'element_settings_about_us' && <AdminAboutUs noAdminLayout />}
      {element.element.type == 'element_settings_privacy_policy' && (
        <AdminPrivacyPolicy noAdminLayout />
      )}
      {element.element.type == 'element_settings_tnc' && <AdminTermsAndConditions noAdminLayout />}
      {element.element.type == 'element_settings_capabilities' && <Capability noAdminLayout />}
      {element.element.type == 'element_settings_price_plan' && <PricingPlan noAdminLayout />}
      {element.element.type == 'report' && <ReportsList data={reports} />}
      {element.element.type == 'wizard' && <WizardListing data={wizards} />}
      {element.element.type == 'access_control' && <AccessControl preview={preview} />}
      {element.element.type == 'table_config' && <TableConfig preview={preview} />}
      {element.element.type == 'login_history' && <LoginHistory preview={preview} />}
      {element.element.type == 'strategy_setting' && <StrategySetting preview={preview} />}
      {element.element.type == 'strategy_recommendation' && <StrategySelection preview={preview} />}
      {element.element.type == 'knowledge_area' && <KnowledgeArea preview={preview} />}
      {element.element.type == 'advice_doc_config' && <AdviceDocConfigSetting preview={preview} />}
      {element.element.type == 'strategy_history' && <AdviceStrategyHistory preview={preview} />}
      {element.element.type == 'product_config' && <ProductConfig preview={preview} />}
      {element.element.type == 'objective_config' && <ObjectiveConfig preview={preview} />}
      {element.element.type == 'portfolio_transaction' && <AssetTransaction preview={preview} />}
      {element.element.type == 'portfolio_option' && <AssetOption preview={preview} />}
      {element.element.type == 'asset_portfolio_option' && <AssetOption preview={preview} />}
      {element.element.type == 'liability_portfolio_option' && <AssetOption preview={preview} />}
      {element.element.type == 'workbook' && (
        <WorkBook
          getFormRecords={getFormRecords}
          handleWorkbookCardClick={handleWorkbookCardClick}
          formRecords={formRecords}
          deleteRecord={deleteRecord}
          progress={progress}
          openGroup={() => setGroupModal(true)}
          openForm={() => setFormModal(true)}
          data={wizards}
          addWorkbookSettingStatus={addWorkbookSettingStatus}
          addWorkbookSettingProgress={addWorkbookSettingProgress}
          handleDragChange={handleWorkbookDrag}
          statuses={statuses}
          getFormDetails={getFormDetails}
        />
      )}
      {element.element.type == 'workbook_setting' && (
        <WorkbookSetting
          deleteRecord={deleteRecord}
          progress={progress}
          openGroup={() => setGroupModal(true)}
          openForm={() => setFormModal(true)}
          data={wizards}
          addWorkbookSettingStatus={addWorkbookSettingStatus}
          addWorkbookSettingProgress={addWorkbookSettingProgress}
          handleDragChange={handleDragChange}
          statuses={statuses}
        />
      )}

      {element.element.type == 'investment' && <InvestmentElement element={element.element} />}
      {element.element.type == 'pie' && (
        <div style={{ marginTop: '10px' }}>
          <CustomPieChart
            element={element}
            preview={preview}
            wizard_history={wizard_history}
            wizard_is_locked={wizard_is_locked}
          />
        </div>
      )}
      {element.element.type == 'donut' && (
        <div style={{ marginTop: '10px' }}>
          <CustomDonutChart
            element={element}
            preview={preview}
            wizard_history={wizard_history}
            wizard_is_locked={wizard_is_locked}
          />
        </div>
      )}

      {element.element.type == 'bar' && element.element.data_source.length > 1 && (
        <BarStackedChart
          element={element}
          preview={preview}
          wizard_history={wizard_history}
          wizard_is_locked={wizard_is_locked}
        />
      )}
      {element.element.type == 'bar' && element.element.data_source.length < 2 && (
        <div style={{ marginTop: '10px' }}>
          <CustomBarChart
            element={element}
            preview={preview}
            wizard_history={wizard_history}
            wizard_is_locked={wizard_is_locked}
          />
        </div>
      )}

      {element.element.type == 'line' && (
        <div style={{ marginTop: '10px' }}>
          <CustomLine
            element={element}
            preview={preview}
            wizard_history={wizard_history}
            wizard_is_locked={wizard_is_locked}
          />
        </div>
      )}
      {element.element.type == 'progress_bar' && (
        <div style={{ marginTop: '10px' }}>
          <ProgressBar
            element={element}
            preview={preview}
            wizard_history={wizard_history}
            wizard_is_locked={wizard_is_locked}
          />
        </div>
      )}

      {element.element.type == 'widget' && (
        <div style={{ marginTop: '25px' }}>
          <h3
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
            }}
          >
            {element.element.title}{' '}
            <Button icon={<PlusOutlined />} onClick={showModal}>
              Add Widget
            </Button>
          </h3>
          <div className="widget_container">
            <div className="widget" style={{ width: `calc(${size}% - 10px)` }}>
              <span className="widget_sizing">
                {size > 33.333 && <ArrowLeftOutlined onClick={decreaseSize} />}
                {size < 100 && <ArrowRightOutlined onClick={increaseSize} />}
              </span>
              <h3 style={{ textAlign: 'center' }}>Widget 1</h3>
              <p>
                Contrary to popular belief, Lorem Ipsum is not simply random text. It has roots in a
                piece of classical Latin literature from 45 BC, making it over 2000 years old.
                Richard McClintock, a Latin professor at Hampden-Sydney College in Virginia, looked
                up one of the more obscure Latin words, consectetur, from a Lorem Ipsum passage, and
                going through the cites of the word in classical literature, discovered the
                undoubtable source. Lorem Ipsum comes from sections 1.10.32 and 1.10.33 of "de
                Finibus Bonorum et Malorum" (The Extremes of Good and Evil) by Cicero
              </p>
            </div>

            <div className="widget" style={{ width: `calc(${size}% - 10px)` }}>
              <span className="widget_sizing">
                {size > 33.333 && <ArrowLeftOutlined onClick={decreaseSize} />}
                {size < 100 && <ArrowRightOutlined onClick={increaseSize} />}
              </span>
              <h3 style={{ textAlign: 'center' }}>Widget 2</h3>
              <p>
                Contrary to popular belief, Lorem Ipsum is not simply random text. It has roots in a
                piece of classical Latin literature from 45 BC, making it over 2000 years old.
                Richard McClintock, a Latin professor at Hampden-Sydney College in Virginia, looked
                up one of the more obscure Latin words, consectetur, from a Lorem Ipsum passage, and
                going through the cites of the word in classical literature, discovered the
                undoubtable source. Lorem Ipsum comes from sections 1.10.32 and 1.10.33 of "de
                Finibus Bonorum et Malorum" (The Extremes of Good and Evil) by Cicero
              </p>
            </div>
          </div>
        </div>
      )}

      <Modal title="Add Widgets" visible={isModalVisible} onOk={handleOk} onCancel={handleCancel}>
        <p>Widgets will go here</p>
        <p>Some contents...</p>
        <p>Some contents...</p>
      </Modal>
      {formModal && (
        <FormPreview
          title="Add Record"
          visible={formModal}
          items={form.items}
          name={form.display_name || form.name}
          handleCancel={() => setFormModal(false)}
          handleOk={() => setFormModal(false)}
        />
      )}
      {groupModal && (
        <FormPreview
          title="Add Record"
          visible={groupModal}
          items={[{ group, type: 'group' }]}
          name={group.display_name || group.name}
          handleCancel={() => setGroupModal(false)}
          handleOk={() => setGroupModal(false)}
        />
      )}
    </>
  )
}

export default ElementField

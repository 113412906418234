import React, { useEffect, useState } from 'react'
import {Button, Form, Input, Modal, Space, Table, Tabs, Select} from 'antd'
import {
  DeleteOutlined,
  EditOutlined,
  PlusOutlined
} from '@ant-design/icons'
import { useFormik } from 'formik'
import * as yup from 'yup'
import {ProductService, AuthService} from 'services'
import { get } from 'lodash'
import moment from 'moment'

const { TabPane } = Tabs
const { Option } = Select

export default function AssetOption({ preview }) {
  const [isModalVisible, setIsModalVisible] = useState(false)
  const [id, setId] = useState(null)
  const [data, setData] = useState([])
  const [activeKey, setActiveKey] = useState('1')
  const [investment_options_list, setInvestmentOptionsList] = useState([])
  const [product_list, setProductList] = useState([])
  const [entity_data, setEntityData] = useState({})
  const [account, setAccount] = useState("")

  const queryString = window.location.search
  const params = new URLSearchParams(queryString)
  const entity_type = params.get('entity_type')
  let entity = null
  if (entity_type == 'client') {
    entity = params.get('entity')
  }

  const getEntityData = async () => {
    const res = await AuthService.detailEntity(entity)
    const new_data = get(res, 'data.data', {})
    // getInvestmentOptionList(new_data.business, false)
    getProductList(new_data.business, false)
    setEntityData(new_data)
    return new_data
  }

  useEffect(() => {
    getInvestmentOptionList(entity_data.business, false, account)
  }, [entity_data, account])

  useEffect(() => {
    getData()
    getEntityData()
  }, [])

  const getInvestmentOptionList = async (business, is_for_investment, product) => {
    const res = await ProductService.getProvider({business, is_for_investment, product})
    setInvestmentOptionsList(get(res, 'data.data', []))
  }

  const getProductList = async (business, is_for_investment) => {
    const res = await ProductService.getProduct({business, is_for_investment})
    setProductList(get(res, 'data.data', []))
  }

  const columns = [
    {
      title: 'Position id',
      dataIndex: 'position_id',
      key: 'position_id',
      render: (text, e) => (
        <div className="table_responsive_cell">
          {text}
        </div>
      ),
    },
    {
      title: 'Option',
      dataIndex: 'option',
      key: 'option',
      render: (text, e) => (
        <div className="table_responsive_cell">
          {e.option && e.option.subject}
        </div>
      ),
    },
    {
      title: 'Account',
      dataIndex: 'account',
      key: 'account',
      render: (text, e) => (
        <div className="table_responsive_cell">
          {e.account && e.account.subject}
        </div>
      ),
    },
    {
      title: 'Units',
      dataIndex: 'units',
      key: 'units',
      render: (text, e) => (
        <div className="table_responsive_cell">
          {text}
        </div>
      ),
    },
    {
      title: 'Balance',
      dataIndex: 'balance',
      key: 'balance',
      render: (text, e) => (
        <div className="table_responsive_cell">
          {text}
        </div>
      ),
    },
    {
      title: 'Cost base',
      dataIndex: 'cost_base',
      key: 'cost_base',
      render: (text, e) => (
        <div className="table_responsive_cell">
          {text}
        </div>
      ),
    },
    {
      title: 'Action',
      key: 'action',
      width: '90px',
      render: (text, record) => (
        <Space size="middle">
          <Button icon={<EditOutlined />} size="medium" onClick={() => getDetail(record.id)} />
          {!record.parent && (
            <Button
              danger
              icon={<DeleteOutlined />}
              size="medium"
              onClick={() => deleteData(record.id)}
            />
          )}
        </Space>
      ),
    }
  ]

  const setInitialValue = () => {
    formik.resetForm()
    formik.setValues(initialValues)
    setId(null)
    setActiveKey('1')
    setAccount("")
  }

  const showModal = () => {
    setIsModalVisible(true)
    setInitialValue()
  }

  const handleOk = () => {
    setIsModalVisible(false)
    setInitialValue()
  }

  const handleCancel = () => {
    setIsModalVisible(false)
    setInitialValue()
  }

  const getDetail = async (id) => {
    await setId(id)
    await setIsModalVisible(true)
    const res = await AuthService.getDetailAssetOption(id)
    formik.setValues(get(res, 'data.data'))
    setAccount(get(res, 'data.data.account'))
  }

  const deleteData = async (id) => {
    const result = window.confirm('Are you sure to delete this option?')
    if (result) {
      await AuthService.deleteAssetOption(id)
      getData()
    }
  }

  const getData = async () => {
    const res = await AuthService.getAssetOption({
      user: entity,
    })
    setData(get(res, 'data.data', []))
  }

  let schema = yup.object().shape({
    position_id: yup.string().required("position_id required"),
    option: yup.string().required("option required"),
    account: yup.string().required("account required"),
    account_number: yup.string().required("account_number required"),
    units: yup.number().required("units required"),
    balance: yup.number().required("balance required"),
    cost_base: yup.number().required("cost_base required")
  })

  const initialValues = {
    position_id: "",
    option: "",
    account: "",
    account_number: "",
    balance: 0,
    units: 0,
    cost_base: 0
  }

  const onChangeAccount  = (value)=>{
    setAccount(value)
    formik.setFieldValue("account", value)
  }

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: initialValues,
    onSubmit: async (values) => {
      values['user'] = entity
      if (id) {
          await AuthService.updateAssetOption(values, id)
      } else {
        await AuthService.saveAssetOption(values)
      }
      setIsModalVisible(false)
      getData()
      setInitialValue()
    },
    validationSchema: schema,
  })

  //
  //  useEffect(() => {
  //   console.log('Formik Errors:', formik.errors);
  // }, [formik.errors]);

  return (
    <div>
      <h1 className="flex_title">
        <span className="title"></span>
        <Button onClick={showModal} icon={<PlusOutlined />} type="primary">
          Add option
        </Button>
      </h1>
      <Table
        className="custom_responsive_table"
        columns={columns}
        dataSource={data}
        rowKey="id"
        pagination={{ defaultPageSize: 50 }}
      />
      <Modal
        title={id ? 'Edit option' : 'Add option'}
        visible={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
        width={'100%'}
        footer={[
          <Button key="back" onClick={handleCancel}>
            Cancel
          </Button>,
          <Button key="submit" type="primary" onClick={formik.handleSubmit}>
            Save
          </Button>,
        ]}
      >
        <Tabs type="card" activeKey={activeKey} onChange={setActiveKey}>
          <TabPane tab="Main" key="1">
            <Form layout="vertical" className={'custom_modal_form'}>
              <Form.Item label="Position id">
                <Input
                  style={{ width: '100%' }}
                  placeholder="position_id"
                  name="position_id"
                  onChange={formik.handleChange}
                  value={formik.values.position_id}
                />
                {formik.touched.position_id && formik.errors.position_id && (
                  <span className="input-error-message">{formik.errors.position_id}</span>
                )}
              </Form.Item>
              <Form.Item label="Account">
                <Select
                  showSearch
                  mode="single"
                  name="account"
                  allowClear
                  style={{ width: '100%' }}
                  placeholder="Please select account"
                  filterOption={(input, option) =>
                    option?.children?.toLowerCase().includes(input.toLowerCase())
                  }
                  value={formik.values.account}
                  onChange={(value) => onChangeAccount(value)}
                >
                  {product_list.map((item) => (
                    <Option key={item.id} value={item.id}>
                      {item.subject}
                    </Option>
                  ))}
                </Select>
                {formik.touched.account && formik.errors.account && (
                  <span className="input-error-message">{formik.errors.account}</span>
                )}
              </Form.Item>
              <Form.Item label="Account number">
                <Input
                  style={{ width: '100%' }}
                  placeholder="account_number"
                  name="account_number"
                  onChange={formik.handleChange}
                  value={formik.values.account_number}
                />
                {formik.touched.account_number && formik.errors.account_number && (
                  <span className="input-error-message">{formik.errors.account_number}</span>
                )}
              </Form.Item>
              <Form.Item label="Investment option">
                <Select
                  showSearch
                  mode="single"
                  name="investment_options"
                  allowClear
                  style={{ width: '100%' }}
                  placeholder="Please select investment option"
                  filterOption={(input, option) =>
                    option?.children?.toLowerCase().includes(input.toLowerCase())
                  }
                  value={formik.values.option}
                  onChange={(value) => formik.setFieldValue("option", value)}
                >
                  {investment_options_list.map((item) => (
                    <Option key={item.id} value={item.id}>
                      {item.subject}
                    </Option>
                  ))}
                </Select>
                {formik.touched.option && formik.errors.option && (
                  <span className="input-error-message">{formik.errors.option}</span>
                )}
              </Form.Item>
              <Form.Item label="Units">
                <Input
                  style={{ width: '100%' }}
                  placeholder="units"
                  type="number"
                  name="units"
                  onChange={formik.handleChange}
                  value={formik.values.units}
                />
                {formik.touched.units && formik.errors.units && (
                  <span className="input-error-message">{formik.errors.units}</span>
                )}
              </Form.Item>
              <Form.Item label="Balance">
                <Input
                  style={{ width: '100%' }}
                  placeholder="balance"
                  type="number"
                  name="balance"
                  onChange={formik.handleChange}
                  value={formik.values.balance}
                />
                {formik.touched.balance && formik.errors.balance && (
                  <span className="input-error-message">{formik.errors.balance}</span>
                )}
              </Form.Item>
              <Form.Item label="Cost base">
                <Input
                  style={{ width: '100%' }}
                  placeholder="cost_base"
                  type="number"
                  name="cost_base"
                  onChange={formik.handleChange}
                  value={formik.values.cost_base}
                />
                {formik.touched.cost_base && formik.errors.cost_base && (
                  <span className="input-error-message">{formik.errors.cost_base}</span>
                )}
              </Form.Item>
            </Form>
          </TabPane>
        </Tabs>
      </Modal>
    </div>
  )
}

import api, { CLIENT_ID, CLIENT_SECRET } from './api'
import Cookies from 'js-cookie'

const endpoints = {
  login: 'login/',
  user: 'user/',
  send_forgot_link: 'email-link/',
  forgot_password_verify: 'forgot-password-verify/',
  verify_account: 'verify-account/',
  change_password: 'change-password/',
  logout: 'logout/',
  entity: 'entity/',
  currentUser: 'current-user/',
  entityAccess: 'entity-access/',
  login_history: 'login-history/',
  asset_portfolio_option: 'asset-portfolio-option/',
  asset_portfolio_transaction: 'asset-portfolio-transaction/',
}

const login = async (data) => {
  data.client_id = CLIENT_ID
  data.client_secret = CLIENT_SECRET
  return api.post(endpoints.login, data)
}

const signup = async (data) => {
  data.client_id = CLIENT_ID
  data.client_secret = CLIENT_SECRET
  return api.post(endpoints.user, data)
}

const send_forgot_link = (data) => {
  return api.post(endpoints.send_forgot_link, data)
}

const forgot_password_verify = (data) => {
  return api.post(endpoints.forgot_password_verify, data)
}

const verify_account = (data) => {
  return api.post(endpoints.verify_account, data)
}

const change_password = (data) => {
  return api.put(endpoints.change_password, data)
}

const logout = () => {
  const data = { token: Cookies.get('token') }
  console.log(data)
  return api.post(endpoints.logout, data)
}

const users = (params = {}) => {
  //user list with client/admin/user
  return api.get(endpoints.user, { params })
}

const createEntity = (data) => {
  //crud entity admin, client, user
  return api.post(endpoints.entity, data)
}

const updateEntity = (id, data) => {
  //crud entity admin, client, user
  return api.put(endpoints.entity + id + '/', data)
}

const detailEntity = (id, params) => {
  //crud entity admin, client, user
  return api.get(endpoints.entity + id + '/', { params })
}

const getCurrentUser = () => {
  return api.get(endpoints.currentUser)
}

const updateUser = (data, id) => {
  return api.put('entity/' + id + '/', data)
}

const updateAccessUser = (data, id) => {
  return api.put(endpoints.entityAccess + id + '/', data)
}

const getLoginHistory = (params = {}) => {
  return api.get(endpoints.login_history, { params })
}

const getAssetOption = async (params) => {
  return api.get(endpoints.asset_portfolio_option, { params })
}

const getDetailAssetOption = async (id) => {
  return api.get(endpoints.asset_portfolio_option + id + '/')
}

const saveAssetOption = async (data) => {
  return api.post(endpoints.asset_portfolio_option, data)
}
const updateAssetOption = async (data, id) => {
  return api.put(endpoints.asset_portfolio_option + id + '/', data)
}

const deleteAssetOption = async (id) => {
  return api.delete(endpoints.asset_portfolio_option + id + '/')
}

const getAssetTransaction = async (params) => {
  return api.get(endpoints.asset_portfolio_transaction, { params })
}

const getDetailAssetTransaction = async (id) => {
  return api.get(endpoints.asset_portfolio_transaction + id + '/')
}

const saveAssetTransaction = async (data) => {
  return api.post(endpoints.asset_portfolio_transaction, data)
}
const updateAssetTransaction = async (data, id) => {
  return api.put(endpoints.asset_portfolio_transaction + id + '/', data)
}

const deleteAssetTransaction = async (id) => {
  return api.delete(endpoints.asset_portfolio_transaction + id + '/')
}

export default {
  login,
  signup,
  users,
  send_forgot_link,
  forgot_password_verify,
  verify_account,
  change_password,
  logout,
  createEntity,
  updateEntity,
  detailEntity,
  getCurrentUser,
  updateUser,
  updateAccessUser,
  getLoginHistory,
  getAssetOption,
  getDetailAssetOption,
  saveAssetOption,
  updateAssetOption,
  deleteAssetOption,
  getAssetTransaction,
  getDetailAssetTransaction,
  saveAssetTransaction,
  updateAssetTransaction,
  deleteAssetTransaction
}

import React, { useState, useEffect } from 'react'
import { Form, Input, DatePicker, InputNumber, Select, Button, Switch } from 'antd'
import moment from 'moment'
import './preview.scss'
import {
  AdminLayout,
  SingleLineText,
  MultipleSelect,
  MultiLineText,
  Boolean,
  Date,
  Number,
} from 'Components'
import { FormService, PageServiceService } from 'services'
import { useNavigate, useParams } from 'react-router-dom'
import { get } from 'lodash'
import RichText from 'Components/Fields/richText'
import Attachment from 'Components/Fields/attachment'
import SingleSelect from 'Components/Fields/singleSelect'
import { ReactSortable } from 'react-sortablejs'
import 'react-quill/dist/quill.snow.css'
import { CloseOutlined, EditOutlined } from '@ant-design/icons'
import Heading from 'Components/Fields/heading'
import TinyEditor from 'Components/TinyEditor'
import ColorSelector from 'Components/Fields/color'
import CheckList from 'Components/Fields/checklist'
import ProviderField from 'Components/Fields/providerField'
const { TextArea } = Input
const { Option } = Select
const { RangePicker } = DatePicker
export default function AddFields() {
  let { id } = useParams()
  let navigate = useNavigate()

  const modules = {
    toolbar: [
      ['bold', 'italic', 'underline', 'strike'], // toggled buttons
      ['blockquote', 'code-block'],

      [{ header: 1 }, { header: 2 }], // custom button values
      [{ list: 'ordered' }, { list: 'bullet' }],
      [{ script: 'sub' }, { script: 'super' }], // superscript/subscript
      [{ indent: '-1' }, { indent: '+1' }], // outdent/indent
      [{ direction: 'rtl' }], // text direction

      [{ size: ['small', false, 'large', 'huge'] }], // custom dropdown
      [{ header: [1, 2, 3, 4, 5, 6, false] }],

      [{ color: [] }, { background: [] }], // dropdown with defaults from theme
      [{ font: [] }],
      [{ align: [] }],

      ['clean'], // remove formatting button
    ],
  }

  const [formData, SetFormData] = useState({
    name: '',
    displayName: '',
    isRequired: false,
    type: 'checklist',
    category: 'core',
    privacy: false,
    layout: 'vertical',
    tooltip: '',
    rows: 2,
    dateVariant: 'date',
    dateVariantType: '',
    display: 'dropdown',
    options: [],
    optionValue: '',
    optionIcon: '',
    optionName: '',
    options_type: '',
    dynamicFields: false,
    width: 100,
    height: 200,
    multiAttachments: false,
    showValues: false,
    isReadOnly: false,
    level: '',
    defaultTextValue: '',
    selectedOptions: null,
    gapLines: 1,
    defaultBool: '',
    numberType: '',
    created_by: false,
    numberFormat: 'integer',
    currency: '',
    defaultNumber: 0,
    defaultRichText: '',
    precision: 0,
    step: '',
    defaultDateValue: '',
    defaultDateRangeFrom: '',
    defaultDateRangeTo: '',
    filtering: {
      dependant_id: null,
      values: {},
    },
    dependsOn: '',
    group: null,
    form: null,
    // turn_on_percentage: false,
    display_fields: [],
    exclude_label: false,
    data_source_for: '',
    assignedTo: false,
    dueDate: false,
    showOverdue: false,
    isMandatory: false,
    isChart: false,
    // showScore:false,
    numberDisplay: 'input',
    numberMin: 0,
    numberMax: 0,
  })
  const [ssOption, setSSOption] = useState([])

  const [forms, SetForms] = useState([])
  const [groups, SetGroups] = useState([])
  const [display_field_options, DisplayFieldOptions] = useState([])
  const [isEditOption, setIsEditOption] = useState(false)
  const [editOptionIndex, setEditOptionIndex] = useState(undefined)

  const getFieldDetail = async () => {
    if (id) {
      const res = await PageServiceService.FieldDetail(id)
      const res_data = get(res, 'data.data')
      let filtering = { ...res_data.filtering }

      await SetFormData((prevState) => ({
        ...prevState,
        ...get(res, 'data.data'),
      }))
      getForms(get(res, 'data.data.form', null))
      getGroups(get(res, 'data.data.group', null))
    } else {
      getForms()
      getGroups()
    }
  }

  useEffect(() => {
    GetFieldList('single_select')
    getFieldDetail()
  }, [])

  const getForms = async (form = null) => {
    try {
      let res = await FormService.getForms({
        data_source: true,
        all_field: true,
      })
      SetForms(get(res, 'data.data', []))
      if (form) {
        set_display_option_during_update('form', form, get(res, 'data.data', []), [])
      }
    } catch (err) {}
  }

  const getGroups = async (group = null) => {
    try {
      let res = await PageServiceService.GroupList({
        data_source: true,
        all_field: true,
      })
      SetGroups(get(res, 'data.data', []))
      if (group) {
        set_display_option_during_update('group', group, [], get(res, 'data.data', []))
      }
    } catch (err) {}
  }

  const GetFieldList = async (value) => {
    const res = await PageServiceService.FieldList(`?type=${value}`)
    setSSOption(get(res, 'data.data'))
  }

  const handleChange = (key, value) => {
    SetFormData((prevState) => ({
      ...prevState,
      [key]: value,
    }))
  }

  const handleSSChange = (key, value) => {
    let data = { ...formData.filtering }
    const ssSelectedOption = ssOption.filter((i) => {
      if (i.id === value) return i
    })
    const filterValue = {}
    if (ssSelectedOption && ssSelectedOption.length > 0) {
      const ssOptions = ssSelectedOption[0].options
      if (ssOptions.length > 0) {
        for (let i = 0; i < ssOptions.length; i++) {
          Object.assign(filterValue, {
            [ssOptions[i].id]: {
              options: [],
              defaultValue: null,
              ...ssOptions[i],
            },
          })
        }
      }
    }
    SetFormData((prevState) => ({
      ...prevState,
      filtering: {
        [key]: value,
        values: filterValue,
      },
      dependsOn: ssSelectedOption.length > 0 ? ssSelectedOption[0] : null,
    }))
  }

  const handleSelectMapOptions = (options, id) => {
    let data = { ...formData.filtering }
    data.values[id].options = []
    for (let i = 0; i < options.length; i++) {
      data.values[id].options.push({
        value: options[i].value,
        name: options[i].children,
      })
    }
    // data.values[id].options = options;
    SetFormData((prevState) => ({
      ...prevState,
      filtering: data,
    }))
  }

  const handleSDMapOptions = (value, id) => {
    let data = { ...formData.filtering }
    data.values[id].defaultValue = value
    SetFormData((prevState) => ({
      ...prevState,
      filtering: data,
    }))
  }
  const handleFromMapOptions = (value, id) => {
    let data = { ...formData.filtering }
    data.values[id].fromValue = value
    SetFormData((prevState) => ({
      ...prevState,
      filtering: data,
    }))
  }
  const handleToMapOptions = (value, id) => {
    let data = { ...formData.filtering }
    data.values[id].toValue = value
    SetFormData((prevState) => ({
      ...prevState,
      filtering: data,
    }))
  }

  const removeOptions = (index) => {
    let optionsUpdated = [...formData.options]
    optionsUpdated.splice(index, 1)
    SetFormData((prevState) => ({
      ...prevState,
      options: optionsUpdated,
    }))
  }
  const addOptions = () => {
    let { options, optionName, optionValue, optionIcon } = formData
    options = JSON.parse(JSON.stringify(options))
    if (optionValue === '') {
      optionValue = optionName
    }
    let optionObj = {
      name: optionName,
      value: optionValue,
      icon: optionIcon,
    }
    if (isEditOption) {
      options[editOptionIndex] = optionObj
    } else {
      options.push(optionObj)
    }
    SetFormData((prevState) => ({
      ...prevState,
      options: options,
      optionValue: '',
      optionName: '',
      optionIcon: '',
    }))
    setIsEditOption(false)
    setEditOptionIndex(undefined)
  }

  const _renderFields = () => {
    switch (formData.type) {
      case 'text':
        return <SingleLineText {...formData} />
      case 'textarea':
        return <MultiLineText {...formData} />
      case 'attachment':
        return <Attachment {...formData} />
      case 'rich_text':
        return <RichText {...formData} />
      case 'multiple_select':
        return <MultipleSelect {...formData} />
      case 'single_select':
        return formData.options_type === 'product_provider' ? (
          <ProviderField {...formData} />
        ) : (
          <SingleSelect {...formData} />
        )
      case 'heading':
        return <Heading {...formData} />
      case 'boolean':
        return <Boolean {...formData} />
      case 'date':
        return <Date {...formData} />
      case 'number':
        return <Number {...formData} />
      case 'color':
        return <ColorSelector {...formData} />
      case 'checklist':
        return <CheckList {...formData} />
      default:
        break
    }
  }

  const OnClickSave = async (e) => {
    e.preventDefault()
    const selectedOptions = Array.isArray(formData.selectedOptions)
      ? formData.selectedOptions.join()
      : formData.selectedOptions
    if (id) {
      await PageServiceService.FieldUpdate(id, {
        ...formData,
        selectedOptions,
      })
      navigate('/admin/fields')
    } else {
      await PageServiceService.FieldAdd(formData, {
        ...formData,
        selectedOptions,
      })
      navigate('/admin/fields')
    }
  }

  const set_display_option = (options_type, value_id) => {
    let field_options = []
    if (options_type === 'form') {
      field_options = get(
        forms.find((o) => o.id === value_id),
        'fields',
        []
      )
      handleChange('group', null)
    } else if (options_type === 'group') {
      field_options = get(
        groups.find((o) => o.id === value_id),
        'fields',
        []
      )
      handleChange('form', null)
    }
    DisplayFieldOptions(field_options || [])
  }

  const set_display_option_during_update = (options_type, value_id, forms = [], groups = []) => {
    let field_options = []
    if (options_type === 'form') {
      field_options = get(
        forms.find((o) => o.id === value_id),
        'fields',
        []
      )
      handleChange('group', null)
    } else if (options_type === 'group') {
      field_options = get(
        groups.find((o) => o.id === value_id),
        'fields',
        []
      )
      handleChange('form', null)
    }
    DisplayFieldOptions(field_options || [])
  }

  return (
    <AdminLayout>
      <div style={{ margin: 10, padding: '35px 40px', backgroundColor: '#fff' }}>
        <h1 className="flex_title">
          <span className="title">Add/Edit Field</span>{' '}
          <Button onClick={OnClickSave} type="primary">
            Save Field
          </Button>
        </h1>
        <div className="preview_layout">
          <Form layout="vertical">
            <Form.Item label="Field Type">
              <Select
                defaultValue={formData.type}
                onChange={(value) => {
                  handleChange('type', value)
                }}
                value={formData.type}
              >
                <Option value="attachment">Attachment</Option>
                <Option value="boolean">Boolean</Option>
                <Option value="date">Date</Option>
                <Option value="gap">Gap</Option>
                <Option value="color">Color</Option>
                <Option value="heading">Heading</Option>
                <Option value="number">Number</Option>
                <Option value="single_select">Select (Single)</Option>
                <Option value="multiple_select">Select (Multiple)</Option>
                <Option value="text">Text (Single line) </Option>
                <Option value="textarea">Text (Multi line)</Option>
                <Option value="rich_text">Text (RTH) </Option>
                <Option value="checklist">Check List</Option>
                {/* <Option value="attachment">Attachment</Option> */}
              </Select>
            </Form.Item>
            <Form.Item label="Field Name">
              <Input
                placeholder="Name of field"
                value={formData.name}
                onChange={(e) => {
                  handleChange('name', e.target.value)
                }}
              />
            </Form.Item>

            {formData.type !== 'gap' && (
              <Form.Item label="Display Name">
                <Input
                  placeholder="Display name of field"
                  value={formData.displayName}
                  onChange={(e) => {
                    handleChange('displayName', e.target.value)
                  }}
                />
              </Form.Item>
            )}
            {formData.type === 'gap' && (
              <Form.Item label="Lines of gap">
                <InputNumber
                  style={{ width: '100%' }}
                  min={1}
                  max={100}
                  placeholder="Gap Lines"
                  value={formData.gapLines}
                  onChange={(value) => {
                    handleChange('gapLines', value)
                  }}
                />
              </Form.Item>
            )}

            {formData.type === 'heading' && (
              <>
                <Form.Item label="Level">
                  <Select
                    value={formData.level}
                    onChange={(value) => {
                      handleChange('level', value)
                    }}
                  >
                    <Option value="h1">H1</Option>
                    <Option value="h2">H2</Option>
                    <Option value="h3">H3</Option>
                    <Option value="h4">H4</Option>
                    <Option value="h5">H5</Option>
                    <Option value="h6">H6</Option>
                  </Select>
                </Form.Item>
              </>
            )}

            <Form.Item label="Category">
              <Select
                defaultValue={formData.category}
                onChange={(value) => {
                  handleChange('category', value)
                }}
                value={formData.category}
              >
                <Option value="core">Core</Option>
                <Option value="custom">Custom</Option>
              </Select>
            </Form.Item>

            {(formData.type === 'attachment' ||
              formData.type === 'boolean' ||
              formData.type === 'date' ||
              formData.type === 'number' ||
              formData.type === 'color' ||
              formData.type === 'single_select' ||
              formData.type === 'multiple_select' ||
              formData.type === 'text' ||
              formData.type === 'textarea' ||
              formData.type === 'rich_text') && (
              <Form.Item label="Layout">
                <Select
                  value={formData.layout}
                  onChange={(value) => {
                    handleChange('layout', value)
                  }}
                >
                  <Option value="vertical">Vertical</Option>
                  <Option value="horizontal">Horizontal</Option>
                </Select>
              </Form.Item>
            )}

            {formData.type === 'multiple_select' ||
            formData.type === 'single_select' ||
            formData.type === 'boolean' ? (
              <Form.Item label="Display">
                <Select
                  defaultValue={formData.display}
                  onChange={(value) => {
                    handleChange('display', value)
                  }}
                  value={formData.display}
                >
                  <Option value="dropdown">Dropdown</Option>
                  <Option value="button">
                    {formData.type === 'boolean' || 'single_select'
                      ? 'Radio'
                      : formData.type === 'multiple_select'
                        ? 'Checkbox'
                        : null}
                  </Option>
                  {(formData.type === 'single_select' || formData.type === 'multiple_select') && (
                    <>
                      <Option value="bar">Bar Chart</Option>
                      <Option value="pie">Pie Chart</Option>
                      <Option value="donut">Donut Chart</Option>
                      {formData.type === 'multiple_select' && (
                        <Option value="in_out_scope">In/Out Scope</Option>
                      )}
                    </>
                  )}
                  {formData.type === 'multiple_select' && formData.options_type !== 'custom' && (
                    <Option value="percentage">Percentage</Option>
                  )}
                </Select>
              </Form.Item>
            ) : null}

            {(formData.type == 'multiple_select' || formData.type == 'single_select') &&
              formData.display == 'bar' && (
                <Form.Item>
                  <label className="inline_switch">
                    <span style={{ display: 'inline-block', width: '140px' }}>
                      Show chart score?
                    </span>
                    <Switch
                      checked={formData.showScore}
                      onChange={(checked) => {
                        handleChange('showScore', checked)
                      }}
                    />
                  </label>
                </Form.Item>
              )}

            {(formData.type === 'attachment' ||
              formData.type === 'boolean' ||
              formData.type === 'date' ||
              formData.type === 'number' ||
              formData.type === 'single_select' ||
              formData.type === 'multiple_select' ||
              formData.type === 'text' ||
              formData.type === 'textarea' ||
              formData.type === 'rich_text') && (
              <Form.Item label="Info text">
                <Input
                  placeholder="Enter default value"
                  value={formData.tooltip}
                  onChange={(e) => {
                    handleChange('tooltip', e.target.value)
                  }}
                />
              </Form.Item>
            )}

            {formData.type === 'number' && (
              <Form.Item label="Type">
                <Select
                  defaultValue={formData.numberType}
                  onChange={(value) => {
                    handleChange('numberType', value)
                  }}
                  value={formData.numberType}
                >
                  <Option value="single" selected>
                    Single
                  </Option>
                  <Option value="range">Range</Option>
                  <Option value="flat_var">Flat &amp; variables</Option>
                  <Option value="flat_var_range">Flat &amp; variables with range</Option>
                </Select>
              </Form.Item>
            )}

            {formData.type === 'number' &&
              (formData.numberType == 'single' || formData.numberType == 'range') && (
                <Form.Item label="Display">
                  <Select
                    defaultValue={formData.numberDisplay}
                    onChange={(value) => {
                      handleChange('numberDisplay', value)
                    }}
                    value={formData.numberDisplay}
                  >
                    <Option value="input" selected>
                      Input
                    </Option>
                    <Option value="slider">Slider</Option>
                  </Select>
                </Form.Item>
              )}

            {/* {formData.type === "number" && (formData.numberType=="single") && formData.numberDisplay=="slider" && (
              <Form.Item label="Max">
                <InputNumber
                  min={1}
                  style={{
                    width:"100%"
                  }}
                  defaultValue={formData.numberMax}
                  onChange={(value) => {
                    handleChange("numberMax", value);
                  }}
                  value={formData.numberMax}
                />
              </Form.Item>
            )} */}
            {formData.type === 'number' &&
              (formData.numberType == 'single' || formData.numberType == 'range') &&
              formData.numberDisplay == 'slider' && (
                <Form.Item label="Min - Max">
                  <InputNumber
                    min={1}
                    style={{
                      width: 'calc(50% - 5px)',
                      marginRight: 10,
                    }}
                    placeholder="Min"
                    defaultValue={formData.numberMin}
                    onChange={(value) => {
                      handleChange('numberMin', value)
                    }}
                    value={formData.numberMin}
                  />
                  <InputNumber
                    min={1}
                    style={{
                      width: 'calc(50% - 5px)',
                    }}
                    placeholder="Max"
                    defaultValue={formData.numberMax}
                    onChange={(value) => {
                      handleChange('numberMax', value)
                    }}
                    value={formData.numberMax}
                  />
                </Form.Item>
              )}

            {formData.type === 'number' && formData.numberType != 'flat_var' && (
              <Form.Item label="Number Format">
                <Select
                  defaultValue={formData.numberFormat}
                  onChange={(value) => {
                    handleChange('numberFormat', value)
                  }}
                  value={formData.numberFormat}
                >
                  <Option value="integer" selected>
                    Integer
                  </Option>
                  <Option value="currency">Currency</Option>
                  <Option value="percentage">Percentage</Option>
                </Select>
              </Form.Item>
            )}

            {(formData.numberFormat === 'currency' || formData.numberType === 'flat_var') && (
              <Form.Item label="Currency">
                <Input
                  placeholder="Enter currency symbol"
                  value={formData.currency}
                  onChange={(e) => {
                    handleChange('currency', e.target.value)
                  }}
                />
              </Form.Item>
            )}

            {formData.type === 'number' && (
              <Form.Item label="Precision">
                <InputNumber
                  style={{ width: '100%' }}
                  placeholder="Enter precision of decimal places"
                  value={formData.precision}
                  onChange={(value) => {
                    handleChange('precision', value)
                    handleChange('step', String(1 / Math.pow(10, value)))
                  }}
                />
              </Form.Item>
            )}

            {formData.type === 'text' ||
            formData.type === 'textarea' ||
            formData.type === 'single_select' ||
            (formData.type === 'number' && formData.numberType === 'single') ||
            formData.type === 'rich_text' ? (
              <>
                <Form.Item label="Width %">
                  <div style={{ width: '100%', display: 'flex' }}>
                    <InputNumber
                      style={{ width: '100%', flex: 1 }}
                      max={100}
                      placeholder="Enter width %"
                      value={formData.width}
                      onChange={(value) => {
                        handleChange('width', value)
                      }}
                    />
                    {formData.type === 'rich_text' && (
                      <InputNumber
                        style={{ width: '100%', flex: 1 }}
                        max={10000}
                        placeholder="Enter height in pixels"
                        value={formData.height}
                        onChange={(value) => {
                          handleChange('height', value)
                        }}
                      />
                    )}
                  </div>
                </Form.Item>
              </>
            ) : null}

            {formData.type === 'text' ||
            formData.type === 'textarea' ||
            formData.type === 'number' ||
            formData.type === 'rich_text'
              ? formData.layout === 'vertical' &&
                formData.type !== 'rich_text' && (
                  <Form.Item>
                    <label className="inline_switch">
                      <span style={{ display: 'inline-block', width: '150px' }}>
                        Allow Dynamic Fields
                      </span>
                      <Switch
                        checked={formData.dynamicFields}
                        onChange={(checked) => {
                          handleChange('dynamicFields', checked)
                        }}
                      />
                    </label>
                  </Form.Item>
                )
              : null}

            {formData.type === 'date' ? (
              <Form.Item label="Variant">
                <Select
                  defaultValue={formData.dateVariant}
                  onChange={(value) => {
                    handleChange('dateVariant', value)
                  }}
                  value={formData.dateVariant}
                >
                  <Option value="date">Date</Option>
                  <Option value="dateRange">Date Range</Option>
                </Select>
              </Form.Item>
            ) : null}
            {(formData.type === 'multiple_select' || formData.type === 'single_select') && (
              <Form.Item label="Options type">
                <Select
                  defaultValue={formData.options_type}
                  onChange={(value) => {
                    handleChange('options_type', value)
                    handleChange('display_fields', [])
                    handleChange('created_by', false)
                  }}
                  value={formData.options_type}
                >
                  <Option value="user" selected>
                    User
                  </Option>
                  <Option value="business">Business</Option>
                  <Option value="client">Client</Option>
                  <Option value="form">Form</Option>
                  <Option value="group">Group</Option>
                  <Option value="custom">Custom</Option>
                  <Option value="score_questionnaire">Score based questionnaire</Option>
                  <Option value="non_score_questionnaire">Non-score based questionnaire</Option>
                  <Option value="option_from">Options from another field</Option>
                  <Option value="advise_for">Advice for</Option>
                  <Option value="product_provider">Provider</Option>
                  <Option value="investment_option">Investment option</Option>
                </Select>
              </Form.Item>
            )}

            {(formData.type === 'single_select' || formData.type === 'multiple_select') &&
              formData.options_type === 'form' && (
                <Form.Item label="Forms">
                  <Select
                    defaultValue={formData.form}
                    onChange={(value) => {
                      handleChange('form', value)
                      set_display_option('form', value)
                    }}
                    value={formData.form}
                  >
                    {forms.map((item) => (
                      <Option key={item.id} value={item.id}>
                        {item.name}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              )}

            {(formData.type === 'single_select' || formData.type === 'multiple_select') &&
              formData.options_type === 'group' && (
                <Form.Item label="Groups">
                  <Select
                    defaultValue={formData.group}
                    onChange={(value) => {
                      handleChange('group', value)
                      set_display_option('group', value)
                    }}
                    value={formData.group}
                  >
                    {groups.map((item) => (
                      <Option key={item.id} value={item.id}>
                        {item.name}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              )}

            {(formData.type === 'single_select' || formData.type === 'multiple_select') &&
              (formData.options_type === 'form' || formData.options_type === 'group') && (
                <Form.Item label="Data Source">
                  <Select
                    defaultValue={formData.data_source_for}
                    onChange={(value) => {
                      handleChange('data_source_for', value)
                    }}
                    value={formData.data_source_for}
                  >
                    <Option></Option>
                    <Option value="client">Client/self</Option>
                    <Option value="user">User</Option>
                    <Option value="business">Business</Option>
                  </Select>
                </Form.Item>
              )}

            {(formData.type === 'single_select' || formData.type === 'multiple_select') &&
              (formData.options_type === 'group' || formData.options_type === 'form') && (
                <Form.Item label="Display option fields">
                  <Select
                    mode="multiple"
                    defaultValue={formData.display_fields}
                    onChange={(value) => {
                      handleChange('display_fields', value)
                    }}
                    value={formData.display_fields}
                  >
                    {display_field_options.map((item) => (
                      <Option value={item.id}>{item.name}</Option>
                    ))}
                  </Select>
                </Form.Item>
              )}

            {(formData.type === 'multiple_select' || formData.type === 'single_select') &&
            formData.options_type === 'custom' ? (
              <Form.Item label="Options">
                <div
                  style={{
                    marginBottom: 15,
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'flex-end',
                  }}
                >
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    <Input
                      placeholder="Option name"
                      style={{ width: 121 }}
                      value={formData.optionName}
                      onChange={(e) => {
                        handleChange('optionName', e.target.value)
                      }}
                    />
                    <Input
                      placeholder="Option value"
                      style={{ width: 121 }}
                      value={formData.optionValue}
                      onChange={(e) => {
                        handleChange('optionValue', e.target.value)
                      }}
                    />
                    <Input
                      style={{ width: 121, marginRight: 5 }}
                      placeholder="Option icon"
                      value={formData.optionIcon}
                      onChange={(e) => handleChange('optionIcon', e.target.value)}
                      required={true}
                    />
                    <span className="material-icons-outlined">{formData.optionIcon}</span>
                  </div>
                  <div>
                    <Button
                      onClick={() => {
                        addOptions()
                      }}
                    >
                      {isEditOption ? 'Edit' : 'Add'}
                    </Button>
                  </div>
                </div>
                {formData.options ? (
                  <ReactSortable
                    list={formData.options}
                    setList={(list) => {
                      handleChange('options', list)
                    }}
                  >
                    {formData.options?.map?.((i, index) => {
                      return (
                        <div
                          key={index}
                          style={{
                            marginBottom: 5,
                            display: 'flex',
                            alignItems: 'center',
                          }}
                        >
                          <div style={{ display: 'flex' }}>
                            <div
                              style={{
                                display: 'flex',
                                height: 35,
                                alignItems: 'center',
                              }}
                            >
                              <div
                                style={{
                                  display: 'flex',
                                  background:
                                    isEditOption && editOptionIndex === index
                                      ? '#98d1b3'
                                      : '#dbdbdb',
                                  height: '100%',
                                  alignItems: 'center',
                                  paddingLeft: 10,
                                  paddingRight: 10,
                                  marginRight: 5,
                                  borderRadius: 10,
                                }}
                              >
                                <div style={{ marginRight: 5 }}>
                                  <strong>Name:</strong> <span>{i.name}</span>
                                </div>
                                <div style={{ marginRight: 5 }}>
                                  <strong>Value:</strong> {i.value}
                                </div>
                                <div style={{ display: 'flex' }}>
                                  <div style={{ marginRight: 3 }}>
                                    <strong> {'Icon:'}</strong>
                                  </div>
                                  {i?.icon ? (
                                    <span className="material-icons-outlined">{i.icon}</span>
                                  ) : (
                                    <span>none</span>
                                  )}
                                </div>
                              </div>
                              <div
                                style={{
                                  background: '#a2c4fc',
                                  paddingRight: 5,
                                  paddingLeft: 5,
                                  borderRadius: 10,
                                  marginRight: 5,
                                }}
                              >
                                <EditOutlined
                                  onClick={() => {
                                    setIsEditOption(true)
                                    setEditOptionIndex(index)
                                    handleChange('optionName', i.name)
                                    handleChange('optionValue', i.value)
                                    handleChange('optionIcon', i.icon)
                                  }}
                                />
                              </div>
                              <div
                                style={{
                                  background: '#fca2a6',
                                  paddingRight: 5,
                                  paddingLeft: 5,
                                  borderRadius: 10,
                                }}
                              >
                                <CloseOutlined
                                  onClick={() => {
                                    removeOptions(index)
                                    setIsEditOption(false)
                                    setEditOptionIndex(undefined)
                                    SetFormData((prevState) => ({
                                      ...prevState,
                                      optionValue: '',
                                      optionName: '',
                                      optionIcon: '',
                                    }))
                                  }}
                                />
                              </div>
                            </div>
                            <div style={{ display: 'flex' }}></div>
                          </div>
                          {/* <span
                            className="custom_tag"
                            style={{ display: "flex" }}
                          >
                            <b>Name</b>="{i.name}" &nbsp; <b>Value</b>="
                            {i.value}" &nbsp; <b>Icon</b>=
                            {i.icon ? (
                              <span className="material-icons-outlined">
                                {i.icon}
                              </span>
                            ) : (
                              "none"
                            )}{" "}
                            &nbsp;{" "}
                          </span> */}
                        </div>
                      )
                    })}
                  </ReactSortable>
                ) : null}
              </Form.Item>
            ) : null}

            {formData.type === 'date' && formData.dateVariant === 'date' ? (
              <Form.Item label="Default Value Type">
                <Select
                  defaultValue={formData.dateVariantType}
                  onChange={(value) => {
                    handleChange('dateVariantType', value)
                  }}
                  value={formData.dateVariantType}
                >
                  <Option value="fixed">Fixed</Option>
                  <Option value="today">Today</Option>
                  <Option value="yesterday">Yesterday</Option>
                  <Option value="start_of_last_calendar_year">Start of last calendar year</Option>
                  <Option value="end_of_last_calendar_year">End of last calendar year</Option>
                  <Option value="start_of_last_financial_year">Start of last financial year</Option>
                  <Option value="end_of_last_financial_year">End of last financial year</Option>
                  <Option value="tomorrow">Tomorrow</Option>
                  <Option value="start_of_next_calendar_year">Start of next calendar year</Option>
                  <Option value="end_of_next_calendar_year">End of next calendar year</Option>
                  <Option value="start_of_next_financial_year">Start of next financial year</Option>
                  <Option value="end_of_next_financial_year">End of next financial year</Option>
                </Select>
              </Form.Item>
            ) : null}

            {formData.type === 'date' && formData.dateVariant === 'dateRange' ? (
              <Form.Item label="Default Value Type">
                <Select
                  defaultValue={formData.dateVariantType}
                  onChange={(value) => {
                    handleChange('dateVariantType', value)
                  }}
                  value={formData.dateVariantType}
                >
                  <Option value="fixed">Fixed</Option>
                  <Option value="last_week">Last week</Option>
                  <Option value="last_fortnight">Last fortnight</Option>
                  <Option value="last_month">Last month</Option>
                  <Option value="last_quarter">Last quarter</Option>
                  <Option value="last_semi_annual">Last semi-annual</Option>
                  <Option value="next_week">Next week</Option>
                  <Option value="next_fortnight">Next fortnight</Option>
                  <Option value="next_month">Next month</Option>
                  <Option value="next_quarter">Next quarter</Option>
                  <Option value="next_semi_annual">Next semi-annual</Option>
                </Select>
              </Form.Item>
            ) : null}

            {(formData.type === 'single_select' ||
              formData.type === 'multiple_select' ||
              formData.type === 'rich_text' ||
              formData.type === 'boolean' ||
              formData.type === 'text' ||
              formData.type === 'textarea' ||
              formData.type === 'date' ||
              (formData.type === 'number' &&
                (formData.numberType === 'single' || formData.numberType === 'range'))) && (
              <>
                {formData.options_type === 'option_from' ? (
                  <Form.Item label={'Pick options from'}>
                    <Select
                      defaultValue={formData.option_from_field}
                      onChange={(value) => {
                        SetFormData((prev) => ({
                          ...prev,
                          option_from_field: value,
                        }))
                      }}
                      value={formData.option_from_field}
                    >
                      <Option></Option>
                      {ssOption.length > 0 &&
                        ssOption.map((i) => (
                          <Option value={i.id} key={i.id}>
                            {i.name}
                          </Option>
                        ))}
                    </Select>
                  </Form.Item>
                ) : (
                  <Form.Item label="Depends On">
                    <Select
                      defaultValue={formData.dependsOn}
                      onChange={(value) => {
                        handleSSChange('dependant_id', value)
                      }}
                      value={formData.dependsOn ? formData.dependsOn.id : ''}
                    >
                      <Option></Option>
                      {ssOption.length > 0 &&
                        ssOption.map((i) => (
                          <Option value={i.id} key={i.id}>
                            {i.name}
                          </Option>
                        ))}
                    </Select>
                  </Form.Item>
                )}
                {formData.dependsOn && formData.type === 'text' && (
                  <Form.Item label="Default Value">
                    <table className="options_filter">
                      <tbody>
                        {formData.dependsOn &&
                          formData.filtering &&
                          formData.filtering.values &&
                          Object.values(formData.filtering.values).length > 0 &&
                          Object.values(formData.filtering.values).map((i, index) => {
                            return (
                              <tr key={i.id}>
                                <td>
                                  <span>
                                    {index + 1}. {i.name ? i.name : ''}
                                  </span>
                                </td>
                                <td>
                                  {/* onChange={(value) => {
                                  handleSDMapOptions(value, i.id);
                                }} */}
                                  <Input
                                    defaultValue={i.defaultValue}
                                    onChange={(e) => {
                                      handleSDMapOptions(e.target.value, i.id)
                                    }}
                                  />
                                </td>
                              </tr>
                            )
                          })}
                      </tbody>
                    </table>
                  </Form.Item>
                )}
                {formData.dependsOn && formData.type === 'textarea' && (
                  <Form.Item label="Default Value">
                    <table className="options_filter">
                      <tbody>
                        {formData.dependsOn &&
                          formData.filtering &&
                          formData.filtering.values &&
                          Object.values(formData.filtering.values).length > 0 &&
                          Object.values(formData.filtering.values).map((i, index) => {
                            return (
                              <tr key={i.id}>
                                <td>
                                  <span>
                                    {index + 1}. {i.name ? i.name : ''}
                                  </span>
                                </td>
                                <td>
                                  <TextArea
                                    defaultValue={i.defaultValue}
                                    value={i.defaultValue}
                                    onChange={(e) => {
                                      handleSDMapOptions(e.target.value, i.id)
                                    }}
                                  />
                                </td>
                              </tr>
                            )
                          })}
                      </tbody>
                    </table>
                  </Form.Item>
                )}
                {formData.dependsOn && formData.type === 'rich_text' && (
                  <Form.Item label="Default Value">
                    <table className="options_filter">
                      <tbody>
                        {formData.dependsOn &&
                          formData.filtering &&
                          formData.filtering.values &&
                          Object.values(formData.filtering.values).length > 0 &&
                          Object.values(formData.filtering.values).map((i, index) => {
                            return (
                              <tr key={i.id}>
                                <td>
                                  <span>
                                    {index + 1}. {i.name ? i.name : ''}
                                  </span>
                                </td>
                                <td>
                                  <TinyEditor
                                    value={i.defaultValue}
                                    onChange={(val) => {
                                      handleSDMapOptions(val, i.id)
                                    }}
                                  />
                                </td>
                              </tr>
                            )
                          })}
                      </tbody>
                    </table>
                  </Form.Item>
                )}
                {(formData.type === 'single_select' || formData.type === 'multiple_select') &&
                  formData.dependsOn &&
                  formData.options_type !== 'option_from' && (
                    <div>
                      <Form.Item label="Filtering">
                        <table className="options_filter">
                          <tbody>
                            {formData.dependsOn &&
                              formData.filtering &&
                              formData.filtering.values &&
                              Object.values(formData.filtering.values).length > 0 &&
                              Object.values(formData.filtering.values).map((i, index) => {
                                return (
                                  <tr key={i.id}>
                                    <td>
                                      <span>
                                        {index + 1}. {i.name ? i.name : ''}
                                      </span>
                                    </td>
                                    <td>
                                      <Select
                                        mode="multiple"
                                        onChange={(value, op) => {
                                          // console.log("op return", op);
                                          handleSelectMapOptions(op, i.id)
                                          // handleSSChange('dependant_id', value);
                                        }}
                                        style={{
                                          maxWidth: 300,
                                          minWidth: 150,
                                        }}
                                        // options={[...formData.options]}
                                        value={i.options}
                                      >
                                        {/* {console.log("options",i)} */}
                                        {formData.options &&
                                          formData.options.map((j) => (
                                            <Option key={j.name} value={j.value}>
                                              {j.name}
                                            </Option>
                                          ))}
                                      </Select>
                                    </td>
                                  </tr>
                                )
                              })}
                          </tbody>
                        </table>
                      </Form.Item>
                      {formData.type === 'single_select' &&
                        formData.options_type !== 'option_from' && (
                          <Form.Item label="Default Value">
                            <table className="options_filter">
                              <tbody>
                                {formData.dependsOn &&
                                  formData.filtering &&
                                  formData.filtering.values &&
                                  Object.values(formData.filtering.values).length > 0 &&
                                  Object.values(formData.filtering.values).map((i, index) => {
                                    return (
                                      <tr key={i.id}>
                                        <td>
                                          <span>
                                            {index + 1}. {i.name ? i.name : ''}
                                          </span>
                                        </td>
                                        <td>
                                          <Select
                                            onChange={(value) => {
                                              handleSDMapOptions(value, i.id)
                                            }}
                                            style={{
                                              maxWidth: 300,
                                              minWidth: 150,
                                            }}
                                            value={i.defaultValue}
                                            // options={[...formData.filtering.values[i.id].options]}
                                          >
                                            <Option></Option>
                                            {formData.options &&
                                              formData.filtering.values[i.id].options.map(
                                                (i, index) => (
                                                  <Option key={index} value={i.value}>
                                                    {i.name}
                                                    {/* {console.log("option", i)} */}
                                                  </Option>
                                                )
                                              )}
                                          </Select>
                                        </td>
                                      </tr>
                                    )
                                  })}
                              </tbody>
                            </table>
                          </Form.Item>
                        )}
                      {formData.type === 'multiple_select' && (
                        <Form.Item label="Default Value">
                          <table className="options_filter">
                            <tbody>
                              {formData.dependsOn &&
                                formData.filtering &&
                                formData.filtering.values &&
                                Object.values(formData.filtering.values).length > 0 &&
                                Object.values(formData.filtering.values).map((i, index) => {
                                  return (
                                    <tr key={i.id}>
                                      <td>
                                        <span>
                                          {index + 1}. {i.name ? i.name : ''}
                                        </span>
                                      </td>
                                      {/* <td>
                                <Select
                                  mode="multiple"
                                  onChange={(value) => {
                                    handleSDMapOptions(value, i.id);
                                  }}
                                  style={{maxWidth:300,minWidth:150}}
                                  value={i.defaultValue?i.defaultValue:[]}
                                >
                                  {formData.options && formData.filtering.values[i.id].options.map(i => <Option key={i} value={i}>{i}</Option>)}
                                </Select>
                              </td> */}
                                      <td>
                                        <Select
                                          mode="multiple"
                                          onChange={(value) => {
                                            handleSDMapOptions(value, i.id)
                                          }}
                                          style={{
                                            maxWidth: 300,
                                            minWidth: 150,
                                          }}
                                          value={i.defaultValue ? i.defaultValue : []}
                                          // options={[...formData.filtering.values[i.id].options]}
                                        >
                                          {formData.options &&
                                            formData.filtering.values[i.id].options.map(
                                              (i, index) => (
                                                <Option key={index} value={i.value}>
                                                  {i.name}
                                                  {/* {console.log("option", i)} */}
                                                </Option>
                                              )
                                            )}
                                        </Select>
                                      </td>
                                    </tr>
                                  )
                                })}
                            </tbody>
                          </table>
                        </Form.Item>
                      )}
                    </div>
                  )}
              </>
            )}

            {formData.dependsOn ? (
              formData.type === 'date' &&
              formData.dateVariantType === 'fixed' && (
                <Form.Item label="Default Value">
                  <table className="options_filter">
                    <tbody>
                      {formData.dependsOn &&
                        formData.filtering &&
                        formData.filtering.values &&
                        Object.values(formData.filtering.values).length > 0 &&
                        Object.values(formData.filtering.values).map((i, index) => {
                          return (
                            <tr key={i.id}>
                              <td>
                                <span>
                                  {index + 1}. {i.name ? i.name : ''}
                                </span>
                              </td>
                              <td>
                                {formData.type === 'date' &&
                                  formData.dateVariant === 'date' &&
                                  formData.dateVariantType === 'fixed' && (
                                    <DatePicker
                                      format={'DD-MM-YYYY'}
                                      style={{ width: '100%' }}
                                      value={
                                        moment(i.defaultValue).isValid()
                                          ? moment(i.defaultValue)
                                          : null
                                      }
                                      onChange={(val) =>
                                        handleSDMapOptions(moment(val).format('YYYY-MM-DD'), i.id)
                                      }
                                    />
                                  )}

                                {formData.type === 'date' &&
                                  formData.dateVariant === 'dateRange' &&
                                  formData.dateVariantType === 'fixed' && (
                                    <RangePicker
                                      format={'DD-MM-YYYY'}
                                      style={{ width: '100%' }}
                                      onChange={(dates, dateStrings) => {
                                        handleFromMapOptions(
                                          moment(dates[0]).format('YYYY-MM-DD'),
                                          i.id
                                        )
                                        handleToMapOptions(
                                          moment(dates[1]).format('YYYY-MM-DD'),
                                          i.id
                                        )
                                      }}
                                      value={
                                        i.fromValue && i.toValue
                                          ? [moment(i.fromValue), moment(i.toValue)]
                                          : null
                                      }
                                    />
                                  )}
                              </td>
                            </tr>
                          )
                        })}
                    </tbody>
                  </table>
                </Form.Item>
              )
            ) : (
              <>
                {formData.type === 'date' &&
                formData.dateVariant === 'date' &&
                formData.dateVariantType === 'fixed' ? (
                  <Form.Item label="Default Value">
                    <DatePicker
                      format={'DD-MM-YYYY'}
                      style={{ width: '100%' }}
                      onChange={(date, dateString) => {
                        handleChange('defaultDateValue', dateString.split('-').reverse().join('-'))
                      }}
                      value={
                        formData.defaultDateValue
                          ? moment(formData.defaultDateValue, 'YYYY-MM-DD')
                          : null
                      }
                    />
                  </Form.Item>
                ) : null}
                {formData.type === 'date' &&
                formData.dateVariant === 'dateRange' &&
                formData.dateVariantType === 'fixed' ? (
                  <Form.Item label="Default Value">
                    <RangePicker
                      format={'DD-MM-YYYY'}
                      style={{ width: '100%' }}
                      onChange={(dates, dateStrings) => {
                        handleChange(
                          'defaultDateRangeFrom',
                          dateStrings[0].split('-').reverse().join('-')
                        )
                        handleChange(
                          'defaultDateRangeTo',
                          dateStrings[1].split('-').reverse().join('-')
                        )
                      }}
                      value={
                        formData.defaultDateRangeFrom
                          ? [
                              moment(formData.defaultDateRangeFrom, 'YYYY-MM-DD'),
                              moment(formData.defaultDateRangeTo, 'YYYY-MM-DD'),
                            ]
                          : []
                      }
                    />
                  </Form.Item>
                ) : null}
              </>
            )}

            {formData.dependsOn && formData.type === 'boolean' && (
              <Form.Item label="Default Value">
                <table className="options_filter">
                  <tbody>
                    {formData.dependsOn &&
                      formData.filtering &&
                      formData.filtering.values &&
                      Object.values(formData.filtering.values).length > 0 &&
                      Object.values(formData.filtering.values).map((i, index) => {
                        return (
                          <tr key={i.id}>
                            <td>
                              <span>
                                {index + 1}. {i.name ? i.name : ''}
                              </span>
                            </td>
                            <td>
                              <Select
                                onChange={(value) => {
                                  handleSDMapOptions(value, i.id)
                                  // handleSSChange('dependant_id', value);
                                }}
                                style={{ maxWidth: 300, minWidth: 150 }}
                                // options={[...formData.filtering.values[i.id].options]}
                                value={i.defaultValue}
                              >
                                {/* {console.log(formData.options)} */}
                                <Option></Option>
                                <Option value="yes">Yes</Option>
                                <Option value="no">No</Option>
                              </Select>
                            </td>
                          </tr>
                        )
                      })}
                  </tbody>
                </table>
              </Form.Item>
            )}
            {formData.dependsOn &&
              formData.type === 'number' &&
              formData.numberType === 'single' && (
                <Form.Item label="Default Value">
                  <table className="options_filter">
                    <tbody>
                      {formData.dependsOn &&
                        formData.filtering &&
                        formData.filtering.values &&
                        Object.values(formData.filtering.values).length > 0 &&
                        Object.values(formData.filtering.values).map((i, index) => {
                          return (
                            <tr key={i.id}>
                              <td>
                                <span>
                                  {index + 1}. {i.name ? i.name : ''}
                                </span>
                              </td>
                              <td>
                                <InputNumber
                                  value={formData.filtering.values[i.id].defaultValue}
                                  onChange={(value) => {
                                    handleSDMapOptions(value, i.id)
                                  }}
                                />
                              </td>
                            </tr>
                          )
                        })}
                    </tbody>
                  </table>
                </Form.Item>
              )}
            {formData.dependsOn &&
              formData.type === 'number' &&
              formData.numberType === 'range' && (
                <Form.Item label="Default Value">
                  <table className="options_filter">
                    <tbody>
                      {formData.dependsOn &&
                        formData.filtering &&
                        formData.filtering.values &&
                        Object.values(formData.filtering.values).length > 0 &&
                        Object.values(formData.filtering.values).map((i, index) => {
                          return (
                            <tr key={i.id}>
                              <td>
                                <span>
                                  {index + 1}. {i.name ? i.name : ''}
                                </span>
                              </td>
                              <td>
                                <InputNumber
                                  value={formData.filtering.values[i.id].fromValue}
                                  onChange={(value) => {
                                    handleFromMapOptions(value, i.id)
                                  }}
                                />{' '}
                                &nbsp;{' '}
                                <InputNumber
                                  value={formData.filtering.values[i.id].toValue}
                                  onChange={(value) => {
                                    handleToMapOptions(value, i.id)
                                  }}
                                />
                              </td>
                            </tr>
                          )
                        })}
                    </tbody>
                  </table>
                </Form.Item>
              )}

            {!formData.dependsOn &&
              (formData.type === 'multiple_select' || formData.type === 'single_select') &&
              formData.options.length > 0 && (
                <Form.Item label="Default Value">
                  <Select
                    mode={formData.type === 'multiple_select' ? 'multiple' : ''}
                    allowClear
                    style={{ minWidth: '120px' }}
                    placeholder="Please select"
                    onChange={(value) => {
                      handleChange('selectedOptions', value)
                    }}
                    value={formData.selectedOptions?.length > 0 ? formData.selectedOptions : []}
                  >
                    {formData.options?.map?.((i, index) => {
                      return (
                        <Option key={index} value={i.value}>
                          {i.name}
                        </Option>
                      )
                    })}
                  </Select>
                </Form.Item>
              )}

            {formData.type === 'text' && !formData.dependsOn && (
              <Form.Item label="Default Value">
                <Input
                  placeholder="Enter default value"
                  value={formData.defaultTextValue}
                  onChange={(e) => {
                    handleChange('defaultTextValue', e.target.value)
                  }}
                />
              </Form.Item>
            )}

            {formData.type === 'color' && !formData.dependsOn && (
              <Form.Item label="Default Color">
                <ColorSelector
                  value={formData.defaultTextValue}
                  onChange={(e) => {
                    handleChange('defaultTextValue', e)
                  }}
                />
              </Form.Item>
            )}

            {!formData.dependsOn && formData.type === 'textarea' && (
              <Form.Item label="Default Value">
                <TextArea
                  placeholder="Enter default value"
                  value={formData.defaultTextValue}
                  onChange={(e) => {
                    handleChange('defaultTextValue', e.target.value)
                  }}
                />
              </Form.Item>
            )}

            {formData.numberType === 'single' && (
              <Form.Item label="Default Value">
                <InputNumber
                  style={{ width: '100%' }}
                  placeholder="Enter default value"
                  value={formData.defaultNumber}
                  onChange={(value) => {
                    handleChange('defaultNumber', value)
                  }}
                />
              </Form.Item>
            )}

            {formData.type === 'boolean' && (
              <Form.Item label="Default Value">
                <Select
                  onChange={(value) => {
                    handleChange('defaultBool', value)
                  }}
                  value={formData.defaultBool}
                >
                  <Option value="yes">Yes</Option>
                  <Option value="no">No</Option>
                </Select>
              </Form.Item>
            )}

            {formData.type === 'rich_text' && !formData.dependsOn && (
              <div>
                <label>Default Value</label>
                <div
                  style={{
                    width: 500,
                    minHeight: 200,
                    marginBottom: 10,
                  }}
                >
                  <TinyEditor
                    value={formData.defaultRichText}
                    onChange={(val) => SetFormData((prev) => ({ ...prev, defaultRichText: val }))}
                  />
                </div>
              </div>
            )}

            {formData.type === 'textarea' ? (
              <Form.Item label="Rows">
                <div style={{ marginBottom: 15 }}>
                  <InputNumber
                    min={2}
                    placeholder="Enter option value"
                    style={{ width: '100%' }}
                    value={formData.rows}
                    onChange={(value) => {
                      handleChange('rows', value)
                    }}
                  />
                </div>
              </Form.Item>
            ) : null}
            {formData.type === 'attachment' ? (
              <Form.Item>
                <label className="inline_switch">
                  <span style={{ display: 'inline-block', width: '150px' }}>
                    Allow multiple files?
                  </span>
                  <Switch
                    checked={formData.multiAttachments}
                    onChange={(checked) => {
                      handleChange('multiAttachments', checked)
                    }}
                  />
                </label>
              </Form.Item>
            ) : null}
            {formData.type === 'single_select' && formData.options_type === 'user' && (
              <Form.Item>
                <label className="inline_switch">
                  <span style={{ display: 'inline-block', width: '100px' }}>Created by</span>
                  <Switch
                    checked={formData.created_by}
                    onChange={(checked) => {
                      handleChange('created_by', checked)
                    }}
                  />
                </label>
              </Form.Item>
            )}

            {(formData.type === 'attachment' ||
              formData.type === 'boolean' ||
              formData.type === 'date' ||
              formData.type === 'number' ||
              formData.type === 'single_select' ||
              formData.type === 'multiple_select' ||
              formData.type === 'text' ||
              formData.type === 'textarea' ||
              formData.type === 'rich_text') && (
              <Form.Item>
                <label className="inline_switch">
                  <span style={{ display: 'inline-block', width: '100px' }}>Is read only?</span>
                  <Switch
                    checked={formData.isReadOnly}
                    onChange={(checked) => {
                      handleChange('isReadOnly', checked)
                    }}
                  />
                </label>
              </Form.Item>
            )}

            {(formData.type === 'attachment' ||
              formData.type === 'boolean' ||
              formData.type === 'date' ||
              formData.type === 'number' ||
              formData.type === 'color' ||
              formData.type === 'single_select' ||
              formData.type === 'multiple_select' ||
              formData.type === 'text' ||
              formData.type === 'textarea' ||
              formData.type === 'rich_text') && (
              <Form.Item>
                <label className="inline_switch">
                  <span style={{ display: 'inline-block', width: '100px' }}>Exclude label ?</span>
                  <Switch
                    checked={formData.exclude_label}
                    onChange={(checked) => {
                      handleChange('exclude_label', checked)
                    }}
                  />
                </label>
              </Form.Item>
            )}

            {(formData.type === 'multiple_select' || formData.type === 'single_select') && (
              <Form.Item>
                <label className="inline_switch">
                  <span style={{ display: 'inline-block', width: '150px' }}>
                    Show option values?
                  </span>
                  <Switch
                    checked={formData.showValues}
                    onChange={(checked) => {
                      handleChange('showValues', checked)
                    }}
                  />
                </label>
              </Form.Item>
            )}
            {/* {(formData.type === 'multiple_select' && formData.options_type !== 'custom') && (
              <Form.Item>
                <label className="inline_switch">
                  <span style={{ display: 'inline-block', width: '150px' }}>
                    Turn on percentage ?
                  </span>
                  <Switch
                    checked={formData.turn_on_percentage}
                    onChange={(checked) => {
                      handleChange('turn_on_percentage', checked);
                    }}
                  />
                </label>
              </Form.Item>
            )} */}
            {formData.type === 'checklist' && (
              <>
                <Form.Item>
                  <label className="inline_switch">
                    <span style={{ display: 'inline-block', width: '100px' }}>Assigned To?</span>
                    <Switch
                      checked={formData.assignedTo}
                      onChange={(checked) => {
                        handleChange('assignedTo', checked)
                      }}
                    />
                  </label>
                </Form.Item>
                <Form.Item>
                  <label className="inline_switch">
                    <span style={{ display: 'inline-block', width: '100px' }}>Due Date?</span>
                    <Switch
                      checked={formData.dueDate}
                      onChange={(checked) => {
                        handleChange('dueDate', checked)
                      }}
                    />
                  </label>
                </Form.Item>
                <Form.Item>
                  <label className="inline_switch">
                    <span style={{ display: 'inline-block', width: '100px' }}>Show Overdue?</span>
                    <Switch
                      checked={formData.showOverdue}
                      onChange={(checked) => {
                        handleChange('showOverdue', checked)
                      }}
                    />
                  </label>
                </Form.Item>
                <Form.Item>
                  <label className="inline_switch">
                    <span style={{ display: 'inline-block', width: '100px' }}>Is Mandatory?</span>
                    <Switch
                      checked={formData.isMandatory}
                      onChange={(checked) => {
                        handleChange('isMandatory', checked)
                      }}
                    />
                  </label>
                </Form.Item>
              </>
            )}
            {(formData.type === 'attachment' ||
              formData.type === 'boolean' ||
              formData.type === 'date' ||
              formData.type === 'number' ||
              formData.type === 'color' ||
              formData.type === 'single_select' ||
              formData.type === 'multiple_select' ||
              formData.type === 'text' ||
              formData.type === 'textarea' ||
              formData.type === 'rich_text') && (
              <>
                <Form.Item>
                  <label className="inline_switch">
                    <span style={{ display: 'inline-block', width: '100px' }}>Is required?</span>
                    <Switch
                      checked={formData.isRequired}
                      onChange={(checked) => {
                        handleChange('isRequired', checked)
                      }}
                    />
                  </label>
                </Form.Item>
                <Form.Item>
                  <label className="inline_switch">
                    <span style={{ display: 'inline-block', width: '100px' }}>Has privacy?</span>
                    <Switch
                      checked={formData.privacy}
                      onChange={(checked) => {
                        handleChange('privacy', checked)
                      }}
                    />
                  </label>
                </Form.Item>
              </>
            )}
          </Form>
          <div className="field_preview">
            <h3>Field Preview</h3>
            <Form>{_renderFields()}</Form>
          </div>
        </div>
      </div>
    </AdminLayout>
  )
}
